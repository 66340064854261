import { render, staticRenderFns } from "./AnnouncmentSection.vue?vue&type=template&id=37f2ca9a&scoped=true&"
import script from "./AnnouncmentSection.vue?vue&type=script&lang=js&"
export * from "./AnnouncmentSection.vue?vue&type=script&lang=js&"
import style0 from "./AnnouncmentSection.vue?vue&type=style&index=0&id=37f2ca9a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37f2ca9a",
  null
  
)

export default component.exports